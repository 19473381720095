import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router-dom';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

//API Requests
const APIDashboard = Loadable(lazy(() => import('views/pages/api/detail/index')));
const APIRequest = Loadable(lazy(() => import('views/pages/api/list_apirequest')));
const APIRequestDetailList = Loadable(lazy(()=>import('views/pages/api/list_api_messages')))
const APIMessagesStatusList =  Loadable(lazy(()=>import('views/pages/api/list_api_message_status')))
const APIMessagesStatusDetailList =  Loadable(lazy(()=>import('views/pages/api/list_api_request_status_detail')))
//Agents
const AgentList = Loadable(lazy(()=> import('views/pages/application/agents/AgentsList')));
const AgentForm = Loadable(lazy(()=> import ('views/pages/application/agents/AgentForm')));

//Companies
const CompaniesList = Loadable(lazy(()=> import('views/pages/application/companies/CompaniesList')));
const CompanyForm = Loadable(lazy(()=> import ('views/pages/application/companies/CompanyForm')));

//Business Numbers
const BusinessNumberList = Loadable(lazy(()=> import('views/pages/application/businessnumbers/BusinessNumberList')));
const BusinessNumberNewForm = Loadable(lazy(()=> import ('views/pages/application/businessnumbers/BusinessNumberNewForm')));
const BusinessNumberEditForm = Loadable(lazy(()=> import ('views/pages/application/businessnumbers/BusinessNumberEditForm')));

//Distribution List
const DistributionListIndex = Loadable(lazy(() => import('views/pages/application/distributionlist/DistributionListIndex')));
const DistributionListDetails = Loadable(lazy(() => import('views/pages/application/distributionlist/DistributionListDetails')));
const DistributionListCreate = Loadable(lazy(() => import('views/pages/application/distributionlist/DistributionListCreate')));
const DistributionListSchedule = Loadable(lazy(() => import('views/pages/application/distributionlist/DistributionListSchedule')));
const DistributionListRequestDetails = Loadable(lazy(() => import('views/pages/application/distributionlist/DistributionListRequestDetails')));
const DistributionListNumberRelations = Loadable(lazy(() => import('views/pages/application/distributionlist/DistributionListNumberRelations')));
const DistributionListAddNumbers = Loadable(lazy(() => import('views/pages/application/distributionlist/DistributionListAddNumbers')));

//Groups
const AgentGroupList = Loadable(lazy(()=> import('views/pages/application/groups/AgentGroupListV2')));
const AgentGroupForm = Loadable(lazy(()=> import ('views/pages/application/groups/AgentGroupForm')));

//Auto Replies Text
const AutoReplyTextForm = Loadable(lazy(()=> import ('views/pages/application/autoreplies/AutoReplyTextForm')));
const AutoReplyTextProfile = Loadable(lazy(()=> import ('views/pages/application/autoreplies/AutoReplyTextProfile')));
const AutoReplyList = Loadable(lazy(()=> import ('views/pages/application/autoreplies/AutoReplyTextList')));
const AutoReplyTextRuleForm = Loadable(lazy(()=> import ('views/pages/application/autoreplies/AutoReplyTextRuleForm')));
const AutoReplyTimeForm = Loadable(lazy(()=> import ('views/pages/application/autoreplies/AutoReplyTimeForm')));
const AutoReplyTimeList = Loadable(lazy(()=> import ('views/pages/application/autoreplies/AutoReplyTimeList')));
const AutoReplyDelayedList = Loadable(lazy(()=> import ('views/pages/application/autoreplies/AutoReplyDelayedList')));
const AutoReplyDelayedForm = Loadable(lazy(()=> import ('views/pages/application/autoreplies/AutoReplyDelayedForm')));
//Mobile End Users
const MobileEndUserForm = Loadable(lazy(()=> import ('views/pages/data/mobileenduser/MobileEndUserForm')));
const MobileEndUserList = Loadable(lazy(()=> import ('views/pages/data/mobileenduser/MobileEndUserList')));

//Chat
const Chat       = Loadable(lazy(() => import('views/pages/messages/chat')));
const ClosedChat = Loadable(lazy(() => import('views/pages/messages/closed')))
const AllOpen    = Loadable(lazy(() => import('views/pages/messages/chat/AllOpen')))
const OpenByID   = Loadable(lazy(() => import('views/pages/messages/chat/OpenChatByID')))
//Logs
const Logs = Loadable(lazy(() => import('views/pages/settings/Logs')));


//Quick Reply / Quick Notes
const QuickReplyList = Loadable(lazy(()=> import ('views/pages/settings/QuickReplyList')));
const QuickReplyForm = Loadable(lazy(()=> import ('views/pages/settings/QuickReplyForm')));
const QuickNotesList = Loadable(lazy(()=> import ('views/pages/settings/QuickNotesList')));
const QuickNotesForm = Loadable(lazy(()=> import ('views/pages/settings/QuickNotesForm'))); 

// ==============================|| MAIN ROUTING ||============================== //

export const ServiceProviderRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: "api-requests",
            element: <APIRequest />,
        },
        {
            path: "api-messages/:id",
            element: <APIRequestDetailList />,
        },
        {
            path: "api-request-messages/:id",
            element: <APIMessagesStatusList />,            
        },
        {
            path: "api/details/:monthyear",
            element: <APIDashboard />
        },        
        {
            path: "api-request/status-detail/:status/:detail",
            element: <APIMessagesStatusDetailList />
        },
        {
            path: "agents",
            element: <AgentList />,
        },
        {
            path: "agent/new",
            element: <AgentForm />,
        },
        {
            path: "agent/edit/:id",
            element: <AgentForm />,
        },
        {
            path: "agent/profile",
            element: <AgentForm profile='true' />,
        },
        {
            path: "agent/profile/:id",
            element: <AgentForm profile='true' />,
        },
        {
            path: "companies",
            element: <CompaniesList />,
        },
        {
            path: "company/new",
            element: <CompanyForm />,
        },
        {
            path: "company/profile/",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/profile/:id",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/edit/:id",
            element: <CompanyForm />,
        },
        {
            path: "business-numbers",
            element: <BusinessNumberList />,
        },
        {
            path: "business-numbers/edit/:id",
            element: <BusinessNumberEditForm />,
        },
        {
            path: "business-numbers/new",
            element: <BusinessNumberNewForm />,
        },
        {
            path: '/distribution-list',
            element: <DistributionListIndex />
        },
        {
            path: '/distribution-list/new/',
            element: <DistributionListCreate />
        },
        {
            path: '/distribution-list/edit/:id',
            element: <DistributionListCreate />
        },
        {
            path: '/distribution-list/view/:id',
            element: <DistributionListDetails />
        },
        {
            path: '/distribution-list/schedule/:id',
            element: <DistributionListSchedule />
        },
        {
            path: '/distribution-list/view/:id/request/:reqid',
            element: <DistributionListRequestDetails />
        },
        {
            path: '/distribution-list/number-relations/:id',
            element: <DistributionListNumberRelations />
        },
        {
            path: '/distribution-list/add-numbers/:id',
            element: <DistributionListAddNumbers />
        },
        {
            path: "agent-groups",
            element: <AgentGroupList />,
        },
        {
            path: "agent-group/edit/:id",
            element: <AgentGroupForm />,
        },
        {
            path: "agent-group/new",
            element: <AgentGroupForm />,
        },
        {
            path: "auto-reply-text/edit/:id",
            element: <AutoReplyTextForm />,
        },
        {
            path: "auto-reply-text/new",
            element: <AutoReplyTextForm />,
        },
        {
            path: "auto-reply-text/profile/:id",
            element: <AutoReplyTextProfile />,
        },
        {
            path: "auto-reply-texts",
            element: <AutoReplyList />,
        },
        {
            path: "auto-reply-text-rule/new/:id",
            element: <AutoReplyTextRuleForm whattodo="new" />,
        },
        {
            path: "auto-reply-time/new",
            element : <AutoReplyTimeForm whattodo="new"/>

        },
        {
            path: "auto-reply-time/edit/:id",
            element : <AutoReplyTimeForm whattodo="edit"/>

        },
        {
            path: "auto-reply-text-rule/edit/:id",
            element: <AutoReplyTextRuleForm whattodo="edit" />,
        },
        {
            path: "auto-reply-text-rules/:id",
            element: <AutoReplyList />,
        },
        {
            path: "auto-reply-times",
            element: <AutoReplyTimeList />,
        },
        {
            path: "auto-reply-delayed",
            element: <AutoReplyDelayedList />,
        },
        {
            path: "auto-reply-delayed/new",
            element: <AutoReplyDelayedForm whattodo="new" />,
        },
        {
            path: "auto-reply-delayed/edit/:id",
            element: <AutoReplyDelayedForm whattodo="edit"/>,
        },
        {
            path: "mobile-end-user",
            element: <MobileEndUserList />,
        },
        {
            path: "mobile-end-user/new",
            element: <MobileEndUserForm />,
        },
        {
            path: "mobile-end-user/edit/:id",
            element: <MobileEndUserForm />,
        },
        {
            path: 'chat',
            element: <Chat />
        },
        {
            path: 'chat/:id',
            element: <OpenByID />
        },
        {
            path: 'closed-chats',
            element: <ClosedChat allClosed={false} />
        },
        {
            path: 'all-closed-chats',
            element: <ClosedChat allClosed={true} />
        },
        {
            path: 'all-chats',
            element: <AllOpen />
        },
        {
            path: 'quick-notes',
            element: <QuickNotesList />
        },
        {
            path: 'quick-note/edit/:id',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-note/new',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-replies',
            element: <QuickReplyList />
        },
        {
            path: 'quick-reply/edit/:id',
            element: <QuickReplyForm />
        },
        {
            path: 'quick-reply/new',
            element: <QuickReplyForm />
        },
        {
            path: 'logs',
            element: <Logs replace to="/" />
        } ,
        {
            path: '*',
            element: <Navigate replace to="/" />
        }
    ]
}

export const ServiceProviderRoutesNoDelay = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: "api-requests",
            element: <APIRequest />,
        },
        {
            path: "api-messages/:id",
            element: <APIRequestDetailList />,
        },
        {
            path: "api-request-messages/:id",
            element: <APIMessagesStatusList />,            
        },
        {
            path: "api/details/:monthyear",
            element: <APIDashboard />
        },        
        {
            path: "api-request/status-detail/:status/:detail",
            element: <APIMessagesStatusDetailList />
        },
        {
            path: "agents",
            element: <AgentList />,
        },
        {
            path: "agent/new",
            element: <AgentForm />,
        },
        {
            path: "agent/edit/:id",
            element: <AgentForm />,
        },
        {
            path: "agent/profile",
            element: <AgentForm profile='true' />,
        },
        {
            path: "agent/profile/:id",
            element: <AgentForm profile='true' />,
        },
        {
            path: "companies",
            element: <CompaniesList />,
        },
        {
            path: "company/new",
            element: <CompanyForm />,
        },
        {
            path: "company/profile/",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/profile/:id",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/edit/:id",
            element: <CompanyForm />,
        },
        {
            path: "business-numbers",
            element: <BusinessNumberList />,
        },
        {
            path: "business-numbers/edit/:id",
            element: <BusinessNumberEditForm />,
        },
        {
            path: "business-numbers/new",
            element: <BusinessNumberNewForm />,
        },
        {
            path: '/distribution-list',
            element: <DistributionListIndex />
        },
        {
            path: '/distribution-list/new/',
            element: <DistributionListCreate />
        },
        {
            path: '/distribution-list/edit/:id',
            element: <DistributionListCreate />
        },
        {
            path: '/distribution-list/view/:id',
            element: <DistributionListDetails />
        },
        {
            path: '/distribution-list/schedule/:id',
            element: <DistributionListSchedule />
        },
        {
            path: '/distribution-list/view/:id/request/:reqid',
            element: <DistributionListRequestDetails />
        },
        {
            path: '/distribution-list/number-relations/:id',
            element: <DistributionListNumberRelations />
        },
        {
            path: '/distribution-list/add-numbers/:id',
            element: <DistributionListAddNumbers />
        },
        {
            path: "agent-groups",
            element: <AgentGroupList />,
        },
        {
            path: "agent-group/edit/:id",
            element: <AgentGroupForm />,
        },
        {
            path: "agent-group/new",
            element: <AgentGroupForm />,
        },
        {
            path: "auto-reply-text/edit/:id",
            element: <AutoReplyTextForm />,
        },
        {
            path: "auto-reply-text/new",
            element: <AutoReplyTextForm />,
        },
        {
            path: "auto-reply-text/profile/:id",
            element: <AutoReplyTextProfile />,
        },
        {
            path: "auto-reply-texts",
            element: <AutoReplyList />,
        },
        {
            path: "auto-reply-text-rule/new/:id",
            element: <AutoReplyTextRuleForm whattodo="new" />,
        },
        {
            path: "auto-reply-time/new",
            element : <AutoReplyTimeForm whattodo="new"/>

        },
        {
            path: "auto-reply-time/edit/:id",
            element : <AutoReplyTimeForm whattodo="edit"/>

        },
        {
            path: "auto-reply-text-rule/edit/:id",
            element: <AutoReplyTextRuleForm whattodo="edit" />,
        },
        {
            path: "auto-reply-text-rules/:id",
            element: <AutoReplyList />,
        },
        {
            path: "auto-reply-times",
            element: <AutoReplyTimeList />,
        },
        {
            path: "mobile-end-user",
            element: <MobileEndUserList />,
        },
        {
            path: "mobile-end-user/new",
            element: <MobileEndUserForm />,
        },
        {
            path: "mobile-end-user/edit/:id",
            element: <MobileEndUserForm />,
        },
        {
            path: 'chat',
            element: <Chat />
        },
        {
            path: 'chat/:id',
            element: <OpenByID />
        },
        {
            path: 'closed-chats',
            element: <ClosedChat allClosed={false} />
        },
        {
            path: 'all-closed-chats',
            element: <ClosedChat allClosed={true} />
        },
        {
            path: 'all-chats',
            element: <AllOpen />
        },
        {
            path: 'quick-notes',
            element: <QuickNotesList />
        },
        {
            path: 'quick-note/edit/:id',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-note/new',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-replies',
            element: <QuickReplyList />
        },
        {
            path: 'quick-reply/edit/:id',
            element: <QuickReplyForm />
        },
        {
            path: 'quick-reply/new',
            element: <QuickReplyForm />
        },
        {
            path: 'logs',
            element: <Logs replace to="/" />
        } ,
        {
            path: '*',
            element: <Navigate replace to="/" />
        }
    ]
}

export const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: "api-requests",
            element: <APIRequest />,
        },
        {
            path: "api-messages/:id",
            element: <APIRequestDetailList />,
        },
        {
            path: "api-request-messages/:id",
            element: <APIMessagesStatusList />,            
        },
        {
            path: "api/details/:monthyear",
            element: <APIDashboard />
        },
        {
            path: "api-request/status-detail/:status/:detail",
            element: <APIMessagesStatusDetailList />
        },
        {
            path: "agents",
            element: <AgentList />,
        },
        {
            path: "agent/new",
            element: <AgentForm />,
        },
        {
            path: "agent/edit/:id",
            element: <AgentForm />,
        },
        {
            path: "agent/profile",
            element: <AgentForm profile='true' />,
        },
        {
            path: "agent/profile/:id",
            element: <AgentForm profile='true' />,
        },
        {
            path: "companies",
            element: <CompaniesList />,
        },
        {
            path: "company/new",
            element: <CompanyForm />,
        },
        {
            path: "company/profile/",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/profile/:id",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/edit/:id",
            element: <CompanyForm />,
        },
        {
            path: "business-numbers",
            element: <BusinessNumberList />,
        },
        {
            path: "business-numbers/edit/:id",
            element: <BusinessNumberEditForm />,
        },
        {
            path: '/distribution-list',
            element: <DistributionListIndex />
        },
        {
            path: '/distribution-list/new/',
            element: <DistributionListCreate />
        },
        {
            path: '/distribution-list/edit/:id',
            element: <DistributionListCreate />
        },
        {
            path: '/distribution-list/view/:id',
            element: <DistributionListDetails />
        },
        {
            path: '/distribution-list/schedule/:id',
            element: <DistributionListSchedule />
        },
        {
            path: '/distribution-list/view/:id/request/:reqid',
            element: <DistributionListRequestDetails />
        },
        {
            path: '/distribution-list/number-relations/:id',
            element: <DistributionListNumberRelations />
        },
        {
            path: '/distribution-list/add-numbers/:id',
            element: <DistributionListAddNumbers />
        },
        {
            path: "agent-groups",
            element: <AgentGroupList />,
        },
        {
            path: "agent-group/edit/:id",
            element: <AgentGroupForm />,
        },
        {
            path: "agent-group/new",
            element: <AgentGroupForm />,
        },
        {
            path: "auto-reply-text/edit/:id",
            element: <AutoReplyTextForm />,
        },
        {
            path: "auto-reply-text/new",
            element: <AutoReplyTextForm />,
        },
        {
            path: "auto-reply-text/profile/:id",
            element: <AutoReplyTextProfile />,
        },
        {
            path: "auto-reply-texts",
            element: <AutoReplyList />,
        },
        {
            path: "auto-reply-text-rule/new/:id",
            element: <AutoReplyTextRuleForm whattodo="new" />,
        },
        {
            path: "auto-reply-time/new",
            element : <AutoReplyTimeForm whattodo="new"/>

        },
        {
            path: "auto-reply-time/edit/:id",
            element : <AutoReplyTimeForm whattodo="edit"/>

        },
        {
            path: "auto-reply-text-rule/edit/:id",
            element: <AutoReplyTextRuleForm whattodo="edit" />,
        },
        {
            path: "auto-reply-text-rules/:id",
            element: <AutoReplyList />,
        },
        {
            path: "auto-reply-times",
            element: <AutoReplyTimeList />,
        },
        {
            path: "auto-reply-delayed",
            element: <AutoReplyDelayedList />,
        },
        {
            path: "auto-reply-delayed/new",
            element: <AutoReplyDelayedForm whattodo="new" />,
        },
        {
            path: "auto-reply-delayed/edit/:id",
            element: <AutoReplyDelayedForm whattodo="edit"/>,
        },
        {
            path: "mobile-end-user",
            element: <MobileEndUserList />,
        },
        {
            path: "mobile-end-user/new",
            element: <MobileEndUserForm />,
        },
        {
            path: "mobile-end-user/edit/:id",
            element: <MobileEndUserForm />,
        },
        {
            path: 'chat',
            element: <Chat />
        },
        {
            path: 'chat/:id',
            element: <OpenByID />
        },
        {
            path: 'closed-chats',
            element: <ClosedChat allClosed={false} />
        },
        {
            path: 'all-chats',
            element: <AllOpen />
        },
        {
            path: 'quick-notes',
            element: <QuickNotesList />
        },
        {
            path: 'quick-note/edit/:id',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-note/new',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-replies',
            element: <QuickReplyList />
        },
        {
            path: 'quick-reply/edit/:id',
            element: <QuickReplyForm />
        },
        {
            path: 'quick-reply/new',
            element: <QuickReplyForm />
        },
        {
            path: '*',
            element: <Navigate replace to="/" />
        }
   
    ]
};

export const AdminRoutesNoDelay = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: "api-requests",
            element: <APIRequest />,
        },
        {
            path: "api-messages/:id",
            element: <APIRequestDetailList />,
        },
        {
            path: "api-request-messages/:id",
            element: <APIMessagesStatusList />,            
        },
        {
            path: "api/details/:monthyear",
            element: <APIDashboard />
        },
        {
            path: "api-request/status-detail/:status/:detail",
            element: <APIMessagesStatusDetailList />
        },
        {
            path: "agents",
            element: <AgentList />,
        },
        {
            path: "agent/new",
            element: <AgentForm />,
        },
        {
            path: "agent/edit/:id",
            element: <AgentForm />,
        },
        {
            path: "agent/profile",
            element: <AgentForm profile='true' />,
        },
        {
            path: "agent/profile/:id",
            element: <AgentForm profile='true' />,
        },
        {
            path: "companies",
            element: <CompaniesList />,
        },
        {
            path: "company/new",
            element: <CompanyForm />,
        },
        {
            path: "company/profile/",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/profile/:id",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/edit/:id",
            element: <CompanyForm />,
        },
        {
            path: "business-numbers",
            element: <BusinessNumberList />,
        },
        {
            path: "business-numbers/edit/:id",
            element: <BusinessNumberEditForm />,
        },
        {
            path: '/distribution-list',
            element: <DistributionListIndex />
        },
        {
            path: '/distribution-list/new/',
            element: <DistributionListCreate />
        },
        {
            path: '/distribution-list/edit/:id',
            element: <DistributionListCreate />
        },
        {
            path: '/distribution-list/view/:id',
            element: <DistributionListDetails />
        },
        {
            path: '/distribution-list/schedule/:id',
            element: <DistributionListSchedule />
        },
        {
            path: '/distribution-list/view/:id/request/:reqid',
            element: <DistributionListRequestDetails />
        },
        {
            path: '/distribution-list/number-relations/:id',
            element: <DistributionListNumberRelations />
        },
        {
            path: '/distribution-list/add-numbers/:id',
            element: <DistributionListAddNumbers />
        },
        {
            path: "agent-groups",
            element: <AgentGroupList />,
        },
        {
            path: "agent-group/edit/:id",
            element: <AgentGroupForm />,
        },
        {
            path: "agent-group/new",
            element: <AgentGroupForm />,
        },
        {
            path: "auto-reply-text/edit/:id",
            element: <AutoReplyTextForm />,
        },
        {
            path: "auto-reply-text/new",
            element: <AutoReplyTextForm />,
        },
        {
            path: "auto-reply-text/profile/:id",
            element: <AutoReplyTextProfile />,
        },
        {
            path: "auto-reply-texts",
            element: <AutoReplyList />,
        },
        {
            path: "auto-reply-text-rule/new/:id",
            element: <AutoReplyTextRuleForm whattodo="new" />,
        },
        {
            path: "auto-reply-time/new",
            element : <AutoReplyTimeForm whattodo="new"/>

        },
        {
            path: "auto-reply-time/edit/:id",
            element : <AutoReplyTimeForm whattodo="edit"/>

        },
        {
            path: "auto-reply-text-rule/edit/:id",
            element: <AutoReplyTextRuleForm whattodo="edit" />,
        },
        {
            path: "auto-reply-text-rules/:id",
            element: <AutoReplyList />,
        },
        {
            path: "auto-reply-times",
            element: <AutoReplyTimeList />,
        },
        {
            path: "mobile-end-user",
            element: <MobileEndUserList />,
        },
        {
            path: "mobile-end-user/new",
            element: <MobileEndUserForm />,
        },
        {
            path: "mobile-end-user/edit/:id",
            element: <MobileEndUserForm />,
        },
        {
            path: 'chat',
            element: <Chat />
        },
        {
            path: 'chat/:id',
            element: <OpenByID />
        },
        {
            path: 'closed-chats',
            element: <ClosedChat allClosed={false} />
        },
        {
            path: 'all-chats',
            element: <AllOpen />
        },
        {
            path: 'quick-notes',
            element: <QuickNotesList />
        },
        {
            path: 'quick-note/edit/:id',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-note/new',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-replies',
            element: <QuickReplyList />
        },
        {
            path: 'quick-reply/edit/:id',
            element: <QuickReplyForm />
        },
        {
            path: 'quick-reply/new',
            element: <QuickReplyForm />
        },
        {
            path: '*',
            element: <Navigate replace to="/" />
        }
   
    ]
};


export const AgentRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Chat />
        },
        {
            path: "agents",
            element: <AgentList />,
        },
        {
            path: "agent/new",
            element: <AgentForm />,
        },
        {
            path: "agent/edit/:id",
            element: <AgentForm />,
        },
        {
            path: "agent/profile",
            element: <AgentForm profile={true} />,
        },
        {
            path: "agent/profile/:id",
            element: <AgentForm profile={true} />,
        },
        
        {
            path: "business-numbers",
            element: <BusinessNumberList />,
        },
        {
            path: "business-numbers/edit/:id",
            element: <BusinessNumberEditForm />,
        },        
        {
            path: "agent-groups",
            element: <AgentGroupList />,
        },
        {
            path: "agent-group/edit/:id",
            element: <AgentGroupForm />,
        },
        {
            path: "agent-group/new",
            element: <AgentGroupForm />,
        },
       
       
        {
            path: "mobile-end-user",
            element: <MobileEndUserList />,
        },
        {
            path: "mobile-end-user/new",
            element: <MobileEndUserForm />,
        },
        {
            path: "mobile-end-user/edit/:id",
            element: <MobileEndUserForm />,
        },
        {
            path: 'chat',
            element: <Chat />
        },
        {
            path: 'chat/:id',
            element: <OpenByID />
        },
        {
            path: 'closed-chats',
            element: <ClosedChat allClosed={false} />
        },
        {
            path: 'quick-notes',
            element: <QuickNotesList />
        },
        {
            path: 'quick-note/edit/:id',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-note/new',
            element: <QuickNotesForm />
        },
        {
            path: 'quick-replies',
            element: <QuickReplyList />
        },
        {
            path: 'quick-reply/edit/:id',
            element: <QuickReplyForm />
        },
        {
            path: 'quick-reply/new',
            element: <QuickReplyForm />
        },
        {
            path: '*',
            element: <Navigate replace to="/" />
        }   
    ]
};

