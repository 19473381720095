import { useRoutes } from 'react-router-dom';

// routes
import {AdminRoutes, AdminRoutesNoDelay, AgentRoutes, ServiceProviderRoutes, ServiceProviderRoutesNoDelay} from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import useAuth from 'hooks/useAuth';
import { constrainPoint } from '@fullcalendar/react';
// ==============================|| ROUTING RENDER ||============================== //


export default function ThemeRoutes() {
    const { user } = useAuth();

    const MainRoutes = (user && user.service_provider) ? 
                            user.delayed_ar_enabled ? 
                                ServiceProviderRoutes :
                                ServiceProviderRoutesNoDelay
                            : (user && parseInt(user.roles_id) !== 100) ? 
                                user.delayed_ar_enabled ? 
                                    AdminRoutes :
                                    AdminRoutesNoDelay
                                : AgentRoutes
    return useRoutes([MainRoutes, LoginRoutes, AuthenticationRoutes]);
}
