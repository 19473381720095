import axios from "axios";
import PerformRecordValidation from "./PerformRecordValidation";
import AxiosErrorHandler from "./AxiosErrorHandler";
import APIGetAuthHeader from "./APIGetAuthHeader";

const APIGetRecords = async (options) => {

    let axiosConfig = await APIGetAuthHeader();
     
    return axios.get((options.server ? options.server : process.env.REACT_APP_API_BASE) + options.url , axiosConfig)
    .then(async res => { return await PerformRecordValidation(res.data,options)}) 
    .catch(async err => {

        if (options.PostErrorFunction){
           if (options.showErrorBeforeFunction !== undefined && options.showErrorBeforeFunction === true )
               await AxiosErrorHandler(err,options);
           return await options.PostErrorFunction(err, options);
        }
        else
           return await AxiosErrorHandler(err,options)
      });
}
export default APIGetRecords;